html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
	background-repeat: round;
}

input[type='date']:required:invalid::-webkit-datetime-edit {
	color: transparent;
}
input[type='date']:focus::-webkit-datetime-edit {
	color: black !important;
}
